<template>
    <div :class="$twMerge('', classes)">
        <p v-if="error" class="text-error text-sm mb-1">
            {{ $t("agreeSelect.error") }}
        </p>

        <div :class="['custom-checkbox', { 'custom-checkbox--checked': checked }]" :aria-checked="checked" @click="toggleCheck" @keydown.space.prevent="toggleCheck" @keydown.enter.prevent="toggleCheck">
            <div class="checkmark" tabindex="0" role="checkbox"></div>
            <span class="text-sm text-neutral-content flex-1">
                {{ $t("agreeSelect.first") }}
                <a class="regulations" :href="$router.resolve({ path: '/agreement' }).href" target="_blank" rel="noopener noreferrer">
                    <span class="text-primary transform-underline hover:underline underline-offset-2">
                        {{ $t("agreeSelect.second") }}
                    </span>
                </a>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: "checked",
        event: "change"
    },
    props: {
        checked: Boolean,
        error: Boolean,
        classes: {
            type: String,
            default: ""
        }
    },
    methods: {
        toggleCheck() {
            this.$emit("change", !this.checked);
        }
    }
};
</script>

<style scoped>
.custom-checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    position: relative;
    outline: none; /* Убираем дефолтную обводку */
}

.custom-checkbox:focus-visible {
    outline: 2px solid #3597fa; /* Обводка при фокусе клавишей Tab */
    outline-offset: 2px;
    border-radius: 5px;
}

.custom-checkbox .checkmark {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    height: 20px;
    width: 20px;
    background-color: #ebeff6;
    border-radius: 5px;
    transition: background-color 0.2s;
}

.custom-checkbox--checked .checkmark {
    background-color: #3597fa;
}

.custom-checkbox--checked .checkmark::after {
    content: "";
    position: absolute;
    left: 7px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
</style>
