<template>
    <Modal windowClasses="" :isOpen="show" @close="closeHandler" @confirm="closeAndClear">
        <div :style="{ 'min-width': windowWidthStyle }" class="pb-4 pt-2 text-left ">
            <div class="flex justify-end">
                <Icon height="32px" icon="ic:round-close" class="text-neutral-content cursor-pointer" @click.native="closeAndClear" />
            </div>

            <p class="text-2xl text-center text-neutral-content mb-8 mt-4">{{ headerName }}</p>
            <div class="px-4">
                <form class="flex flex-col gap-2 " novalidate @submit.stop.prevent="send">
                    <MyInput v-model.trim="name" :label="$t('auth.form.name')" :validation="validations.name" v-if="view === 'signUp'" />

                    <MyInput class="white" v-model.trim="email" label="E-mail" inputType="email" name="email" @input="formatEmail" :validation="validations.email" v-if="view !== 'confirm'" />

                    <MyInput class="white" v-model.trim="password" :label="$t('auth.form.password')" inputType="password" :validation="validations.password" v-if="view !== 'resetPassword' && view !== 'confirm'" />
                    <div v-if="view === 'signIn'" class="text-right  text-primary text-sm mt-2 cursor-pointer hover:underline" @click.stop="changeView('resetPassword')">
                        {{ $t("auth.forgotPassword") }}
                    </div>
                    <MyInput v-model.trim="code" :label="$t('auth.form.code')" :validation="validations.code" @input="formatCode" v-if="view === 'confirm'" />
                </form>
                <div class="flex flex-col gap-3 mt-12">
                    <Captcha link="captchaSignup" v-if="view === 'signUp' || view === 'signIn'" ref="captcha" />
                    <Agree :error="errorAgree" classes="mt-3" v-model="agree" v-if="view === 'signUp'" />
                    <MyButton @click.native="send" variant="primary" size="large" class="w-full" :value="buttonName" :spinner="spinner" :disabled="!agree" />
                    <div v-if="view === 'signIn'" class="text-center  text-primary text-sm mt-2 cursor-pointer hover:underline" @click.stop="changeView('signUp')">
                        {{ $t("auth.offerSignUp") }}
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import MyInput from "./ui/input.vue";
import MyButton from "./ui/button.vue";
import Modal from "./ui/Modal/Modal.vue";
import Agree from "./agree.vue";
import { Icon } from "@iconify/vue2";

import Captcha from "./Captcha.vue";

export default {
    components: {
        MyInput,
        MyButton,
        Agree,
        Icon,
        Captcha,
        Modal
    },
    props: {
        view: {
            type: String,
            default: "signIn"
        },
        show: {
            type: Boolean,
            default: false
        },
        closeHandler: {
            type: Function,
            default: () => {}
        },
        changeView: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            name: "",
            email: "",
            password: "",
            code: "",
            agree: true,
            errorAgree: false,
            validations: {
                name: {
                    isValid: true,
                    text: ""
                },
                email: {
                    isValid: true,
                    text: ""
                },
                password: {
                    isValid: true,
                    text: ""
                },
                code: {
                    isValid: true,
                    text: ""
                }
            },
            spinner: false
        };
    },
    mounted() {
        // Слушатель для обновления ширины окна при изменении
        window.addEventListener("resize", this.updateWindowWidth);
    },
    beforeDestroy() {
        // Удаляем слушатель при уничтожении компонента
        window.removeEventListener("resize", this.updateWindowWidth);
    },
    computed: {
        ...mapGetters(["referralLink"]),
        windowWidthStyle() {
            let width;
            if (this.windowWidth > 500) {
                width = "450px";
            } else {
                width = "100%";
            }
            return width;
        },
        headerName() {
            switch (this.view) {
                case "signIn":
                    return this.$t("auth.headerName.myProfile");
                case "signUp":
                    return this.$t("auth.headerName.reg");
                case "resetPassword":
                    return this.$t("auth.headerName.resetPassword");
                case "confirm":
                    return this.$t("auth.headerName.confirm");
                default:
                    return null;
            }
        },
        buttonName() {
            switch (this.view) {
                case "signIn":
                    return this.$t("auth.submit.logIn");
                case "signUp":
                    return this.$t("auth.submit.reg");
                case "resetPassword":
                    return this.$t("auth.submit.send");
                case "confirm":
                    return this.$t("auth.submit.confirm");
                default:
                    return null;
            }
        }
    },
    watch: {
        agree() {
            if (this.agree) {
                this.errorAgree = false;
            }
        },
        name() {
            if (this.name) {
                this.validations.name.isValid = true;
            }
        },
        email() {
            if (this.email) {
                this.validations.email.isValid = true;
            }
        },
        password() {
            if (this.password) {
                this.validations.password.isValid = true;
            }
        },
        code() {
            if (this.code) {
                this.validations.code.isValid = true;
            }
        }
    },
    methods: {
        ...mapActions(["userSignup", "userSignin", "userResetPassword", "userConfirmResetPassword"]),
        closeAndClear() {
            // this.();
            this.email = "";
            this.password = "";
            this.code = "";
            this.name = "";
            this.errorAgree = false;
            this.agree = true;
            this.validations.name.isValid = true;
            this.validations.email.isValid = true;
            this.validations.password.isValid = true;
            this.validations.code.isValid = true;
            this.closeHandler();
        },
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
        closeAuth() {
            this.$emit("close-auth");
        },
        formatEmail(email) {
            this.email = email.toLowerCase();
        },
        formatCode(code) {
            this.code = code.toLowerCase();
        },
        validateEmail(email) {
            const re = /\S+@\S+\.\S+/;
            return re.test(email);
        },
        send() {
            if (!this.spinner) {
                this.spinner = true;
                switch (this.view) {
                    case "signIn":
                        this.signIn();
                        break;
                    case "signUp":
                        this.signUp();
                        break;
                    case "resetPassword":
                        this.resetPassword();
                        break;
                    case "confirm":
                        this.confirm();
                        break;
                }
            }
        },
        successHandler() {
            this.closeAndClear();
            this.$router.push({
                name: "profile",
                params: { view: "orders" }
            });
            this.email = "";
            this.password = "";
            this.spinner = false;
            this.name = "";
            this.errorAgree = false;
        },
        errorHandler(data) {
            if (data.email) {
                this.validations.email.text = data.error;
                this.validations.email.isValid = false;
            } else {
                this.validations.password.text = data.error;
                this.validations.password.isValid = false;
            }
            this.spinner = false;
        },
        signUp() {
            let isValidCaptcha = this.$refs.captcha.validateCaptcha();
            if (this.name.length === 0) {
                this.validations.name.isValid = false;
                this.validations.name.text = this.$t("auth.validation.reg.name");

                this.spinner = false;
            } else if (!this.validateEmail(this.email)) {
                this.validations.email.isValid = false;
                this.validations.email.text = this.$t("auth.validation.reg.email");

                this.spinner = false;
            } else if (this.password.length < 6) {
                this.validations.password.isValid = false;
                this.validations.password.text = this.$t("auth.validation.reg.password");
                this.spinner = false;
            } else if (!isValidCaptcha) {
                this.spinner = false;
            } else if (!this.agree) {
                this.errorAgree = true;
                this.spinner = false;
            } else {
                let data = {
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    referralLink: this.referralLink
                };

                this.userSignup({ data, successHandler: this.successHandler, errorHandler: this.errorHandler });
            }
        },
        signIn() {
            let isValidCaptcha = this.$refs.captcha.validateCaptcha();
            if (!this.validateEmail(this.email)) {
                this.validations.email.isValid = false;
                this.validations.email.text = this.$t("auth.validation.login.email");
                this.spinner = false;
            } else if (this.password.length === 0) {
                this.validations.password.isValid = false;
                this.validations.password.text = this.$t("auth.validation.login.password");
                this.spinner = false;
            } else if (!isValidCaptcha) {
                this.spinner = false;
            } else {
                let data = { email: this.email, password: this.password };
                this.userSignin({ data, successHandler: this.successHandler, errorHandler: this.errorHandler });
            }
        },
        resetPassword() {
            if (!this.validateEmail(this.email)) {
                this.validations.email.isValid = false;
                this.validations.email.text = this.$t("auth.validation.recover.email");
                this.spinner = false;
            } else {
                const successHandler = () => {
                    this.spinner = false;
                    this.changeView("confirm");
                };

                const errorHandler = message => {
                    this.validations.email.isValid = false;
                    this.validations.email.text = message;
                    this.spinner = false;
                };

                this.userResetPassword({ email: this.email, successHandler, errorHandler });
            }
        },
        confirm() {
            if (this.code.length === 0) {
                this.validations.code.isValid = false;
                this.validations.code.text = this.$t("auth.validation.recover.code");
                this.spinner = false;
            } else {
                const successHandler = () => {
                    this.code = "";
                    this.spinner = false;
                    this.changeView("signIn");
                };

                const errorHandler = message => {
                    this.validations.code.isValid = false;
                    this.validations.code.text = message;
                    this.spinner = false;
                };

                let data = {
                    email: this.email,
                    code: this.code
                };

                this.userConfirmResetPassword({ data, successHandler, errorHandler });
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
