<template lang="">
    <div>
        <Modal windowClasses="" :isOpen="open" @close="closeHandler" @confirm="closeHandler">
            <div class="flex justify-end mb-4">
                <Icon height="32px" icon="ic:round-close" class="text-neutral-content cursor-pointer" @click.native="closeHandler" />
            </div>
            <form v-if="!isFinished" @submit.prevent="handleSubmit" class="flex flex-col items-center">
                <p class="text-center text-lg  text-neutral mb-6">
                    {{ $t("wishwindow.title") }}
                </p>
                <star-rating :star-size="30" active-color="#3597FA" inactive-color="#E8E8E8" :show-rating="false" :padding="20" v-model="rating"></star-rating>
                <MyInput :isTextArea="true" inputClasses="" class="white w-full mt-4" v-model.trim="text" :placeholder="$t('wishwindow.input')" name="text" :validation="validations.email" />
                <Button :spinner="isSubmit" variant="primary" classes="w-full mt-4" size="" :value="$t('wishwindow.submit')" />
            </form>
            <div v-if="isFinished" class="flex flex-col items-center">
                <Icon icon="weui:done2-outlined" class="text-success" height="70px" />
                <p class="text-center text-lg  text-neutral mt-4 mb-8">
                    {{ $t("wishwindow.finishTitle") }}
                </p>
            </div>
        </Modal>
    </div>
</template>
<script>
import Modal from "./ui/Modal/Modal.vue";
import StarRating from "vue-star-rating";

import MyInput from "./ui/input.vue";

import Button from "./ui/button.vue";

import { Icon } from "@iconify/vue2";

import axios from "axios";

const maxTextLength = 500;

export default {
    props: {
        open: Boolean,
        onClose: Function
    },
    data() {
        return {
            text: "",
            rating: 0,
            isSubmit: false,
            isFinished: false,
            validations: {
                email: {
                    text: "Пожалуйста, введите текст пожелания",
                    isValid: true
                }
            }
        };
    },
    components: {
        Modal,
        StarRating,
        Icon,
        MyInput,
        Button
    },
    watch: {
        text() {
            if (this.text.length <= maxTextLength) {
                this.validations.email.isValid = true;
            }
        }
    },
    methods: {
        handleSubmit() {
            console.log(this.text, this.rating);
            if (this.text.length > maxTextLength) {
                this.validations.email = {
                    text: this.$t("wishwindow.maxTextLength", { maxTextLength }),
                    isValid: false
                };
                return;
            }
            if (this.rating > 5 || this.rating < 0) {
                throw new Error("Rating should be less than 5");
            }
            // t/
            this.isSubmit = true;
            axios
                .post("/feedback/wish", {
                    text: this.text,
                    rating: this.rating
                })
                .then(() => {
                    this.isFinished = true;
                })
                .catch((e) => {
                    this.$toast.error(e.response.data.message);
                })
                .finally(() => {
                    this.isSubmit = false;
                });
        },
        closeHandler() {
            this.text = "";
            this.rating = 0;
            this.onClose();
        }
    }
};
</script>
<style lang=""></style>
