<template lang="">
    <div :class="mainClass">
        <MyInput v-model="search" classes="mb-7" inputWrapClasses=" bg-base-200 rounded-md" placeholder="Поиск" preIcon="fluent:search-16-filled" />
        <div
            class="overflow-x-hidden overflow-y-auto shadow-inner rounded-md px-2 py-2"
            :style="{
                // height:
                'max-height': '60vh'
            }"
        >
            <div v-if="!!filtredCurrencies.length">
                <div :class="getItemClass(curr)" v-for="curr in filtredCurrencies" @click="changeCurrency(curr)" :key="curr._id">
                    <div class="flex items-center">
                        <img :alt="curr.name" class="w-6 h-6 rounded-xs mr-1 object-cover" :src="$getImageUrl(curr.image)" />
                        <p class="text-neutral text-sm">
                            {{ curr.name }}
                        </p>
                    </div>

                    <div>
                        <p v-if="showReserve" class="ml-1 text-xs text-neutral-content">{{ curr.reserve }} {{ curr.code }}</p>
                    </div>
                </div>
            </div>

            <div v-else>
                <p class="py-2 text-center text-neutral-content text-sm">
                    Не найдено
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import MyInput from "./input.vue";
import { twMerge } from "tailwind-merge";

export default {
    data() {
        return {
            search: ""
        };
    },
    components: {
        MyInput
    },
    computed: {
        filtredCurrencies() {
            let search = this.search.toLowerCase().trim();
            console.log(search);
            return this.currencies.filter(c => {
                return c.name.toLowerCase().includes(search) || c.code.toLowerCase().includes(search);
            });
        },
        mainClass() {
            return twMerge("", this.classes);
        },
        server() {
            return this.URL.server;
        }
    },
    props: {
        currencies: {
            type: Array,
            default: () => []
        },
        changeCurrency: Function,
        selectedId: {
            type: String,
            default: ""
        },
        classes: {
            type: String,
            default: ""
        },
        showReserve: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getItemClass(curr) {
            return twMerge("p-3 flex items-center justify-between mb-2  rounded-md hover:bg-secondary transition-all cursor-pointer", ` ${curr._id == this.selectedId ? "bg-secondary" : ""}`);
        }
    }
};
</script>
<style lang=""></style>
