<template>
    <div class="flex flex-col items-center">
        <div class="flex flex-col items-center card bg-base-100">
            <router-link :to="accrualsLink" v-if="!!order.cashback" :class="cashbackClass">
                <Icon icon="mdi:cash-sync" class="text-success" height="30px" />
                <p class="text-center" v-html="$t('orderPage.resolved.cashbackInfo', { cashback: order.cashback })" />

                <!-- </p> -->
            </router-link>

            <div v-if="addressTransaction" class="mb-3">
                <h6 class="text-neutral">
                    {{ $t("orderPage.resolved.trans") }}
                    <a target="_blank" :href="addressTransaction" class="text-primary hover:underline">
                        <span>{{ $t("orderPage.resolved.transLink") }}</span>
                        <!-- <Icon color="#3597fa" icon="akar-icons:link-out" height="16" /> -->
                    </a>
                </h6>
            </div>

            <div v-if="!!order.sendQrInfo" class="mb-3 text-center text-lg text-primary">
                {{ order.sendQrInfo }}
            </div>

            <div class="text-neutral mb-3">
                <p class="text-center">
                    {{ $t("orderPage.resolved.thank") }}
                    <a target="_blank" :href="reviewLink" class="text-primary hover:underline"><span>Bestchange</span></a
                    >.
                </p>
                <p class="text-center mt-2">
                    {{ $t("orderPage.resolved.wishText") }}
                    <span class="text-primary hover:underline cursor-pointer" @click="openWishHandler">
                        {{ $t("orderPage.resolved.wishOpenBtn") }}
                    </span>
                    .
                </p>
            </div>

            <h6 class="text-neutral ">{{ $t("orderPage.resolved.t") }}</h6>
        </div>
        <WishWindow :open="openWish" :onClose="closeWishHandler" />
    </div>
</template>

<script>
import CONSTANT_LINKS from "../../../constants/links.json";
import { getFullBlockchainTransactionUrl } from "../../../utils/currency/getFullBlockchainTransactionUrl";

import { Icon } from "@iconify/vue2";
import { mapGetters } from "vuex";

import WishWindow from "../../../components/WishWindow.vue";

//  comp
export default {
    data() {
        return {
            openWish: false
        };
    },
    props: {
        order: Object
    },
    components: {
        Icon,
        WishWindow
    },
    computed: {
        ...mapGetters(["isAuthenticated"]),
        cashbackClass() {
            let style = "mb-4 flex items-center gap-1 rounded-sm py-1.5 px-3 text-center text-neutral-content bg-light-success";
            return style;
        },
        accrualsLink() {
            if (this.isAuthenticated) {
                return "/profile/accruals";
            }
            return "";
        },
        addressTransaction() {
            let order = this.order;
            let linkTake = order.currency.give.currency.transactionLink;
            let addressPayment = order.fields.give.find(field => field.isAddress);
            return getFullBlockchainTransactionUrl(linkTake, addressPayment?.value);
        },
        reviewLink() {
            let locale = this.$i18n.locale === "ru" ? "rus" : "eng";
            let domain = CONSTANT_LINKS.bestchange[locale];
            return domain + "/ex-bank-exchanger.html";
        }
    },
    methods: {
        closeWishHandler() {
            this.openWish = false;
        },
        openWishHandler() {
            this.openWish = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.resolvedSection {
    padding: 0px 16px 16px 16px;
    margin-top: 15px;
}

.resolvedSection .textLine {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.resolvedSection .link {
    color: #3597fa;
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.resolvedSection .link:hover {
    text-decoration: underline;
}

.resolvedSection .wishText {
    margin-right: 5px;
    display: flex;
}

.resolvedSection .thank {
    text-align: center;
}

.resolvedSection .qr {
    text-align: center;
    font-weight: 600;
}
</style>
